const UserRolesIntToString = {
  1: "NOT_CONFIRMED",
  2: "ADMINISTRATOR",
  3: "TECH_SUPPORT",
  4: "MANAGEMENT",
  5: "API_USER",
  6: "TECH_SPEC",
  7: "ADMIN_PANEL_MANAGER",
  8: "TECHSUPPORT_MANAGER",
  9: "TECH_SPEC_SENIOR",
  10: "ANALYST",
  11: "DELETED",
  12: "SALES_MANAGER",
};
Object.freeze(UserRolesIntToString);

const UserRolesStringToInt = {
  NOT_CONFIRMED: 1,
  ADMINISTRATOR: 2,
  TECH_SUPPORT: 3,
  MANAGEMENT: 4,
  API_USER: 5,
  TECH_SPEC: 6,
  ADMIN_PANEL_MANAGER: 7,
  TECHSUPPORT_MANAGER: 8,
  TECH_SPEC_SENIOR: 9,
  ANALYST: 10,
  DELETED: 11,
  SALES_MANAGER: 12,
};
Object.freeze(UserRolesStringToInt);

const UserPermissions = {
  USERS_LIST: 1,
  USERS_CHANGE_ROLE: 2,
  USERS_DELETE: 3,
  CONFIGURATIONS_LIST_OWN: 4,
  CONFIGURATIONS_LIST_ALL: 5,
  CONFIGURATIONS_EDIT_OWN: 6,
  CONFIGURATIONS_EDIT_ALL: 7,
  CONFIGURATIONS_CREATE: 8,
  QUESTIONS_MANAGE: 17,
  LOGS_LIST: 18,
  MOUNTS_LIST: 19,
  MOUNTS_SET_STATE: 21,
  CLONE_LIST: 23,
  SEND_PUSHES: 25,
  GET_STATS: 27,
  SCHEMES_LIST: 28,
  SETTINGS_LIST: 31,
  GET_ANY_USERGROUP: 32,
  GET_OWN_USERGROUP: 34,
  LIST_TECHSUPPORT_REQUESTS: 35,
  CLOSE_OWN_TECHSUPPORT_REQUESTS: 37,
  CLOSE_ANY_TECHSUPPORT_REQUESTS: 38,
  USER_INFO_EDIT_ANY: 40,
  CHAT_ANY: 42,
  FACTORY_PACKAGE_LIST: 45,
  USERGROUP_MEMBER_CHANGE_STATUS: 47,
  ADVANCED_STATISTICS: 48,
  UPDATE_CHANNEL_WRITE: 57,
  FEEDBACKS_LIST_ALL: 58,
  MAILING: 60,
  MANAGE_BRANDS: 61,
  MANAGE_DEVICES: 62,
  MANAGE_STRUCTURES: 63,
  MANAGE_TRANSLATIONS: 64,
  MANAGE_AUTOHELPER: 65,
  MANAGE_CLONE_TIPS: 66,
  CLONE_COUNT_SET: 67,
  MANAGE_CAR_ALARM_FEATURES: 68,
  ORDER_REQUESTS_LIST: 69,
  ORDER_REQUESTS_CLOSE_ANY: 72,
  ORDER_REQUESTS_LIST_OWN: 74,
  MANAGE_ORDER_REQUEST_TOPICS: 75,
  MANAGE_ARTICLES: 77,
  ARTICLES_VIEW_DRAFTS: 78,
  OPEN_MANUAL_REQUESTS_FULL: 81,
  OPEN_MANUALS_FULL: 82,
};
Object.freeze(UserPermissions);

export { UserRolesIntToString, UserRolesStringToInt, UserPermissions };
