import { useUserDispatch } from "./UserContext";
import { createContext, useContext } from "react";
import { ResponseCode } from "../enums/ResponseCode";

function b64EncodeUnicode(str) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(
      /%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
        return String.fromCharCode("0x" + p1);
      }
    )
  );
}

var refreshInProgress = null;
const BackendContext = createContext();
const BackendProvider = ({ children }) => {
  const userDispatch = useUserDispatch();

  function handleAuth(address, request) {
    if (request.queryParams) {
      let queryParams = new URLSearchParams();
      let any = false;
      Object.keys(request.queryParams).forEach((k) => {
        let v = request.queryParams[k];
        if (!v && v !== false && v !== 0) return;
        if (Array.isArray(v) && v.length === 0) return;
        if (Array.isArray(v)) {
          v.forEach((v2) => {
            queryParams.append(k, v2);
          });
        } else {
          queryParams.append(k, v);
        }
        any = true;
      });
      if (any) {
        address += "?" + queryParams;
      }
    }

    return fetch(address, request)
      .then((requestData) => {
        return requestData.json();
      })
      .then((responseJson) => {
        if (
          responseJson.code === ResponseCode.TOKEN_EXPIRED ||
          responseJson.code === ResponseCode.ACCESS_DENIED ||
          responseJson.code === ResponseCode.AUTH_FAILURE
        ) {
          if (refreshInProgress == null) {
            refreshInProgress = fetch("frontend/Users/RefreshToken", {
              method: "post",
              headers: {
                "X-Pandora-Specialist-Client-Id":
                  localStorage.getItem("clientId"),
                "X-Pandora-Specialist-Client-Type": 3,
                "X-Pandora-Specialist-Lang": localStorage.getItem("i18nextLng"),
              },
            })
              .then((refreshData) => {
                return refreshData.json();
              })
              .then((refreshResponseJson) => {
                if (refreshResponseJson.code === 0) {
                  localStorage.setItem(
                    "role",
                    refreshResponseJson.content.role
                  );
                  localStorage.setItem(
                    "permissions",
                    JSON.stringify(refreshResponseJson.content.permissions)
                  );
                } else {
                  userDispatch({ type: "SIGN_OUT_SUCCESS" });
                }
                return refreshResponseJson;
              });
          }
          return refreshInProgress.then((refreshResponseJson) => {
            refreshInProgress = null;
            if (refreshResponseJson.code === 0) {
              return fetch(address, request).then((retryRequestData) => {
                return retryRequestData.json();
              });
            } else {
              return refreshResponseJson;
            }
          });
        } else {
          return responseJson;
        }
      });
  }

  function bckUsersLogin(login, password) {
    return fetch("frontend/Users/Login", {
      method: "post",
      headers: {
        Authorization:
          b64EncodeUnicode(login) + " " + b64EncodeUnicode(password),
        "X-Pandora-Specialist-Client-Id": localStorage.getItem("clientId"),
        "X-Pandora-Specialist-Client-Type": 3,
        "X-Pandora-Specialist-Lang": localStorage.getItem("i18nextLng"),
      },
    });
  }

  function bckUsersActivate(jwtToken) {
    return fetch(`frontend/Users/Activate`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: `"${jwtToken}"`,
    });
  }

  function bckUsersLogout() {
    return fetch("frontend/Users/Logout", {
      method: "post",
      headers: {},
    });
  }

  function bckUsersSignIn(fullname, email, password, sendActivationEmail) {
    return fetch("frontend/Users/SignIn", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ fullname, email, password, sendActivationEmail }),
    });
  }

  function bckUsersRequestPasswordReset(login) {
    return fetch(`frontend/Users/RequestPasswordReset`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ login }),
    });
  }

  function bckUsersResetPassword(token, newPassword) {
    return fetch(`frontend/Users/ResetPassword`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token, newPassword }),
    });
  }

  function bckUsersListAll(searchLogin, searchRoles, pageNumber, pageSize) {
    return handleAuth(`frontend/Users/ListAll`, {
      method: "get",
      headers: {},
      queryParams: { searchLogin, searchRoles, pageNumber, pageSize },
    });
  }

  function bckUsersSetRole(login, role) {
    return handleAuth("frontend/Users/SetRole", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ login, role }),
    });
  }

  function bckUsersCheckToken(dispatch) {
    return handleAuth("frontend/Users/CheckToken", {
      method: "get",
      headers: {},
    });
  }

  function bckTechsupportRequestsGetConfig(requestId) {
    return handleAuth(`frontend/TechsupportRequests/GetConfig`, {
      method: "get",
      headers: {},
      queryParams: { requestId },
    });
  }

  function bckTechsupportRequestsGetFullInfo(requestId) {
    return handleAuth(`frontend/TechsupportRequests/GetFullInfo`, {
      method: "get",
      headers: {},
      queryParams: { requestId },
    });
  }

  function bckOrderRequestsGetFullInfo(requestId) {
    return handleAuth(`frontend/OrderRequests/GetFullInfo`, {
      method: "get",
      headers: {},
      queryParams: { requestId },
    });
  }

  function bckUsersDeleteUser(login) {
    return handleAuth("frontend/Users/DeleteUser", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ login }),
    });
  }

  function bckConfigurationsList(
    carBrandId,
    carModelId,
    alarmModelId,
    filterLang,
    filterUser,
    langId,
    filterByCompleteness,
    pageNumber,
    pageSize
  ) {
    return handleAuth(`frontend/Configurations/List`, {
      method: "get",
      headers: {},
      queryParams: {
        carBrandId,
        carModelId,
        alarmModelId,
        filterLang,
        filterUser,
        langId,
        filterByCompleteness,
        pageNumber,
        pageSize,
      },
    });
  }

  function bckFastMountsList(
    statusQuery,
    userQuery,
    deviceQuery,
    brandId,
    modelId,
    alarmId,
    filterLang,
    cityQuery,
    deviceId,
    lang,
    pageNumber,
    pageSize
  ) {
    return handleAuth(`frontend/FastMounts/List`, {
      method: "get",
      headers: {},
      queryParams: {
        statusQuery,
        userQuery,
        deviceQuery,
        brandId,
        modelId,
        alarmId,
        filterLang,
        cityQuery,
        deviceId,
        lang,
        pageNumber,
        pageSize,
      },
    });
  }

  function bckDevicesList(deviceName, specOnly, withConfig) {
    return handleAuth(`frontend/Devices/List`, {
      method: "get",
      headers: {},
      queryParams: { deviceName, specOnly, withConfig },
    });
  }

  function bckFeedbackList(
    showAll,
    userQuery,
    deviceQuery,
    langQuery,
    pageNumber,
    pageSize
  ) {
    return handleAuth(`frontend/Feedback/List`, {
      method: "get",
      headers: {},
      queryParams: {
        showAll,
        userQuery,
        deviceQuery,
        langQuery,
        pageNumber,
        pageSize,
      },
    });
  }

  function bckDevicesListDevices(
    deviceName,
    onlyWithSubsystems,
    pageNumber,
    pageSize
  ) {
    return handleAuth(`frontend/Devices/ListDevices`, {
      method: "get",
      headers: {},
      queryParams: { deviceName, onlyWithSubsystems, pageNumber, pageSize },
    });
  }

  function bckDevicesListSubsystems(
    subsystemNameFilter,
    onlyWithFirmwares,
    sortByFirmwareDate,
    pageNumber,
    pageSize
  ) {
    return handleAuth(`frontend/Devices/ListSubsystems`, {
      method: "get",
      headers: {},
      queryParams: {
        subsystemNameFilter,
        onlyWithFirmwares,
        sortByFirmwareDate,
        pageNumber,
        pageSize,
      },
    });
  }

  function bckSettingsGenerateSettingsEditorInfo(noCache) {
    return handleAuth(`frontend/Settings/GenerateSettingsEditorInfo`, {
      method: "get",
      headers: {},
      queryParams: { noCache },
    });
  }

  function bckDevicesGetSubsystemInfo(id, lang) {
    return handleAuth(`frontend/Devices/GetSubsystemInfo`, {
      method: "get",
      headers: {},
      queryParams: { id, lang },
    });
  }

  function bckCarsListBrands(
    onlyWithConfig,
    onlyWithEquipment,
    onlyWithModels
  ) {
    return handleAuth(`frontend/Cars/ListBrands`, {
      method: "get",
      headers: {},
      queryParams: { onlyWithConfig, onlyWithEquipment, onlyWithModels },
    });
  }

  function bckCarsListModels(brandId, onlyWithConfig, onlyWithEquipment) {
    return handleAuth(`frontend/Cars/ListModels`, {
      method: "get",
      headers: {},
      queryParams: { brandId, onlyWithConfig, onlyWithEquipment },
    });
  }

  function bckCarsListEquipments(modelId, canCode, lang) {
    return handleAuth(`frontend/Cars/ListEquipments`, {
      method: "get",
      headers: {},
      queryParams: { modelId, canCode, lang },
    });
  }

  function bckCarsGetEquipmentInfo(equipId, lang, includeAllLang) {
    return handleAuth(`frontend/Cars/GetEquipmentInfo`, {
      method: "get",
      headers: {},
      queryParams: { equipId, lang, includeAllLang },
    });
  }

  function bckConfigurationsGetChecklist(configId) {
    return handleAuth(`frontend/Configurations/GetChecklist`, {
      method: "get",
      headers: {},
      queryParams: { configId },
    });
  }

  function bckConfigurationCreateOrUpdate(
    configId,
    name,
    shortDescription,
    fullDescription,
    carEquipmentId,
    alarmId,
    questionIds,
    dxsFilename,
    dxsData,
    mountingMapUrl,
    images,
    access,
    language
  ) {
    return handleAuth(`frontend/Configurations/CreateOrUpdate`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        configId,
        name,
        shortDescription,
        fullDescription,
        carEquipmentId,
        alarmId,
        questionIds,
        dxsFilename,
        dxsData,
        mountingMapUrl,
        images,
        access,
        language,
      }),
    });
  }

  function bckConfigurationsGetAdditionalInfo(configId) {
    return handleAuth(`frontend/Configurations/GetAdditionalInfo`, {
      method: "get",
      headers: {},
      queryParams: { configId },
    });
  }

  function bckConfigurationsGetDxsData(configId) {
    return handleAuth(`frontend/Configurations/GetDxsData`, {
      method: "get",
      headers: {},
      queryParams: { configId },
    });
  }

  function bckConfigurationsDelete(configId) {
    return handleAuth(`frontend/Configurations/Delete`, {
      method: "post",
      headers: {},
      body: "",
      queryParams: { configId },
    });
  }

  function bckFilesUploadMountingMap(filename, content) {
    const formData = new FormData();
    formData.append("Filename", filename);
    formData.append("Content", content);

    return handleAuth(`frontend/Files/UploadMountingMap`, {
      method: "post",
      headers: {},
      body: formData,
    });
  }

  function bckFilesUploadCarModelMountingMap(modelId, lang, content) {
    const formData = new FormData();
    formData.append("ModelId", modelId);
    formData.append("Lang", lang);
    formData.append("Content", content);

    return handleAuth(`frontend/Files/UploadCarModelMountingMap`, {
      method: "post",
      headers: {},
      body: formData,
    });
  }

  function bckFilesUploadChatFile(filename, content) {
    const formData = new FormData();
    formData.append("Filename", filename);
    formData.append("Content", content);

    return handleAuth(`frontend/Files/UploadChatFile`, {
      method: "post",
      headers: {},
      body: formData,
    });
  }

  function bckFilesUploadCarBrandImage(id, content) {
    const formData = new FormData();
    formData.append("Id", id);
    formData.append("Content", content);

    return handleAuth(`frontend/Files/UploadCarBrandImage`, {
      method: "post",
      headers: {},
      body: formData,
    });
  }

  function bckFilesUploadCarModelImage(id, content) {
    const formData = new FormData();
    formData.append("Id", id);
    formData.append("Content", content);

    return handleAuth(`frontend/Files/UploadCarModelImage`, {
      method: "post",
      headers: {},
      body: formData,
    });
  }

  function bckFilesUploadDeviceImage(id, content) {
    const formData = new FormData();
    formData.append("Id", id);
    formData.append("Content", content);

    return handleAuth(`frontend/Files/UploadDeviceImage`, {
      method: "post",
      headers: {},
      body: formData,
    });
  }

  function bckFilesUploadSubsystemImage(id, content) {
    const formData = new FormData();
    formData.append("Id", id);
    formData.append("Content", content);

    return handleAuth(`frontend/Files/UploadSubsystemImage`, {
      method: "post",
      headers: {},
      body: formData,
    });
  }

  function bckFilesUploadArticleImage(id, content) {
    const formData = new FormData();
    formData.append("Id", id);
    formData.append("Content", content);

    return handleAuth(`frontend/Files/UploadArticleImage`, {
      method: "post",
      headers: {},
      body: formData,
    });
  }

  function bckFilesUploadArticleElementImage(id, content) {
    const formData = new FormData();
    formData.append("Id", id);
    formData.append("Content", content);

    return handleAuth(`frontend/Files/UploadArticleElementImage`, {
      method: "post",
      headers: {},
      body: formData,
    });
  }

  function bckFilesUploadMountingPointGuideImage(id, content) {
    const formData = new FormData();
    formData.append("Id", id);
    formData.append("Content", content);

    return handleAuth(`frontend/Files/UploadMountingPointGuideImage`, {
      method: "post",
      headers: {},
      body: formData,
    });
  }

  function bckFilesUploadMountingScheme(id, content) {
    const formData = new FormData();
    formData.append("Id", id);
    formData.append("Content", content);

    return handleAuth(`frontend/Files/UploadMountingScheme`, {
      method: "post",
      headers: {},
      body: formData,
    });
  }

  function bckFilesUploadCarMountingMap(id, content) {
    const formData = new FormData();
    formData.append("Id", id);
    formData.append("Content", content);

    return handleAuth(`frontend/Files/UploadCarMountingMap`, {
      method: "post",
      headers: {},
      body: formData,
    });
  }

  function bckFilesUploadOpenManualRequestFile(id, content) {
    const formData = new FormData();
    formData.append("Id", id);
    formData.append("Content", content);

    return handleAuth(`frontend/Files/UploadOpenManualRequestFile`, {
      method: "post",
      headers: {},
      body: formData,
    });
  }

  function bckFilesUploadOpenManualFile(id, content) {
    const formData = new FormData();
    formData.append("Id", id);
    formData.append("Content", content);

    return handleAuth(`frontend/Files/UploadOpenManualFile`, {
      method: "post",
      headers: {},
      body: formData,
    });
  }

  function bckFilesUploadSubsystemFirmware(id, content) {
    const formData = new FormData();
    formData.append("Id", id);
    formData.append("Content", content);

    return handleAuth(`frontend/Files/UploadSubsystemFirmware`, {
      method: "post",
      headers: {},
      body: formData,
    });
  }

  function bckFilesUploadAvatar(content) {
    const formData = new FormData();
    formData.append("Content", content);

    return handleAuth(`frontend/Files/UploadAvatar`, {
      method: "post",
      headers: {},
      body: formData,
    });
  }

  function bckFilesUploadUpdateChannelFile(fileId, content) {
    const formData = new FormData();
    formData.append("FileId", fileId);
    formData.append("Content", content);

    return handleAuth(`frontend/Files/UploadUpdateChannelFile`, {
      method: "post",
      headers: {},
      body: formData,
    });
  }

  function bckFilesUploadConfigurationImage(configId, filename, content) {
    const formData = new FormData();
    formData.append("ConfigId", configId);
    formData.append("Filename", filename);
    formData.append("Content", content);

    return handleAuth(`frontend/Files/UploadConfigurationImage`, {
      method: "post",
      headers: {},
      body: formData,
    });
  }

  function bckQuestionsListParts(lang) {
    return handleAuth(`frontend/Questions/ListParts`, {
      method: "get",
      headers: {},
      queryParams: { lang },
    });
  }

  function bckQuestionsListSubparts(lang) {
    return handleAuth(`frontend/Questions/ListSubparts`, {
      method: "get",
      headers: {},
      queryParams: { lang },
    });
  }

  function bckQuestionsListQuestions(lang) {
    return handleAuth(`frontend/Questions/ListQuestions`, {
      method: "get",
      headers: {},
      queryParams: { lang },
    });
  }

  function bckQuestionsCreateOrUpdatePart(id, name, lang) {
    return handleAuth(`frontend/Questions/CreateOrUpdatePart`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id, name, lang }),
    });
  }

  function bckQuestionsCreateOrUpdateSubpart(id, name, parentPartId, lang) {
    return handleAuth(`frontend/Questions/CreateOrUpdateSubpart`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id, name, parentPartId, lang }),
    });
  }

  function bckQuestionsCreateOrUpdateQuestion(
    id,
    algorithm,
    expectedResult,
    answers,
    parentSubpartId,
    lang
  ) {
    return handleAuth(`frontend/Questions/CreateOrUpdateQuestion`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id,
        algorithm,
        expectedResult,
        answers,
        parentSubpartId,
        lang,
      }),
    });
  }

  function bckQuestionsDeletePart(partId) {
    return handleAuth(`frontend/Questions/DeletePart`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      queryParams: { partId },
    });
  }

  function bckQuestionsDeleteSubpart(subpartId) {
    return handleAuth(`frontend/Questions/DeleteSubpart`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      queryParams: { subpartId },
    });
  }

  function bckQuestionsDeleteQuestion(questionId) {
    return handleAuth(`frontend/Questions/DeleteQuestion`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      queryParams: { questionId },
    });
  }

  function bckUsersChangePassword(oldPassword, newPassword) {
    return handleAuth(`frontend/Users/ChangePassword`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ oldPassword, newPassword }),
    });
  }

  function bckUpdateChannelList(showAll, lang) {
    return handleAuth(`frontend/UpdateChannel/List`, {
      method: "get",
      headers: {},
      queryParams: { showAll, lang },
    });
  }

  function bckUpdateChannelCreateFile(version, versionInt, updateChannelId) {
    return handleAuth(`frontend/UpdateChannel/CreateFile`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ version, versionInt, updateChannelId }),
    });
  }

  function bckUpdateChannelSetIsPublished(id, value) {
    const formData = new FormData();
    formData.append("Id", id);
    formData.append("Value", value);

    return handleAuth(`frontend/UpdateChannel/SetIsPublished`, {
      method: "post",
      headers: {},
      body: formData,
    });
  }

  function bckUpdateChannelAddUser(channelId, userId) {
    const formData = new FormData();
    formData.append("channelId", channelId);
    formData.append("userId", userId);

    return handleAuth(`frontend/UpdateChannel/AddUser`, {
      method: "post",
      headers: {},
      body: formData,
    });
  }

  function bckUpdateChannelEditFileDescription(fileId, description, lang) {
    const formData = new FormData();
    formData.append("fileId", fileId);
    formData.append("description", description);
    formData.append("lang", lang);

    return handleAuth(`frontend/UpdateChannel/EditFileDescription`, {
      method: "post",
      headers: {},
      body: formData,
    });
  }

  function bckUpdateChannelEditRequirements(channelId, requirements, lang) {
    const formData = new FormData();
    formData.append("channelId", channelId);
    formData.append("requirements", requirements);
    formData.append("lang", lang);

    return handleAuth(`frontend/UpdateChannel/EditRequirements`, {
      method: "post",
      headers: {},
      body: formData,
    });
  }

  function bckUpdateChannelEditFileVersion(fileId, version, versionInt) {
    const formData = new FormData();
    formData.append("fileId", fileId);
    formData.append("version", version);
    formData.append("versionInt", versionInt);

    return handleAuth(`frontend/UpdateChannel/EditFileVersion`, {
      method: "post",
      headers: {},
      body: formData,
    });
  }

  function bckUpdateChannelDuplicateFile(fileId, channelId) {
    const formData = new FormData();
    formData.append("fileId", fileId);
    formData.append("channelId", channelId);

    return handleAuth(`frontend/UpdateChannel/DuplicateFile`, {
      method: "post",
      headers: {},
      body: formData,
    });
  }

  function bckUpdateChannelRemoveUser(channelId, userId) {
    const formData = new FormData();
    formData.append("channelId", channelId);
    formData.append("userId", userId);

    return handleAuth(`frontend/UpdateChannel/RemoveUser`, {
      method: "post",
      headers: {},
      body: formData,
    });
  }

  function bckUpdateChannelListFiles(
    channelId,
    showAll,
    lang,
    pageNumber,
    pageSize
  ) {
    return handleAuth(`frontend/UpdateChannel/ListFiles`, {
      method: "get",
      headers: {},
      queryParams: { channelId, showAll, lang, pageNumber, pageSize },
    });
  }

  function bckUpdateChannelGetActualVersion(clientType) {
    return handleAuth(`frontend/UpdateChannel/GetActualVersion`, {
      method: "get",
      headers: {},
      queryParams: { clientType },
    });
  }

  function bckTranslationsList(id, text, pageNumber, pageSize) {
    return handleAuth(`frontend/Translations/List`, {
      method: "get",
      headers: {},
      queryParams: { id, text, pageNumber, pageSize },
    });
  }

  function bckCloneTipsList(pageNumber, pageSize) {
    return handleAuth(`frontend/CloneTips/List`, {
      method: "get",
      headers: {},
      queryParams: { pageNumber, pageSize },
    });
  }

  function bckCarAlarmFeaturesList(type) {
    return handleAuth(`frontend/CarAlarmFeatures/List`, {
      method: "get",
      headers: {},
      queryParams: { type },
    });
  }

  function bckAutohelperLogs(userQuery, pageNumber, pageSize) {
    return handleAuth(`frontend/Autohelper/Logs`, {
      method: "get",
      headers: {},
      queryParams: { userQuery, pageNumber, pageSize },
    });
  }

  function bckUpdateChannelListUsers(channelId, pageNumber, pageSize) {
    return handleAuth(`frontend/UpdateChannel/ListUsers`, {
      method: "get",
      headers: {},
      queryParams: { channelId, pageNumber, pageSize },
    });
  }

  function bckLogsList(
    userQuery,
    alarmQuery,
    processTypeQuery,
    eventTypeQuery,
    filterLang,
    cityQuery,
    deviceId,
    lang,
    pageNumber,
    pageSize
  ) {
    return handleAuth(`frontend/Logs/List`, {
      method: "get",
      headers: {},
      queryParams: {
        userQuery,
        alarmQuery,
        processTypeQuery,
        eventTypeQuery,
        filterLang,
        cityQuery,
        deviceId,
        lang,
        pageNumber,
        pageSize,
      },
    });
  }

  function bckPushesList(pageNumber, pageSize) {
    return handleAuth(`frontend/Pushes/List`, {
      method: "get",
      headers: {},
      queryParams: { pageNumber, pageSize },
    });
  }

  function bckMailingList(pageNumber, pageSize) {
    return handleAuth(`frontend/Mailing/List`, {
      method: "get",
      headers: {},
      queryParams: { pageNumber, pageSize },
    });
  }

  function bckFastMountsSetComment(mountId, comment) {
    return handleAuth(`frontend/FastMounts/SetComment`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: `"${encodeURIComponent(comment)}"`,
      queryParams: { mountId },
    });
  }

  function bckFastMountsSetState(mountId, state) {
    return handleAuth(`frontend/FastMounts/SetState`, {
      method: "post",
      headers: {},
      queryParams: { mountId, state },
    });
  }

  function bckClonesList(
    deviceId,
    firmware,
    status,
    userQuery,
    carModelQuery,
    canQuery,
    vinQuery,
    algorithmQuery,
    versionQuery,
    includeData,
    pageNumber,
    pageSize
  ) {
    return handleAuth(`frontend/Clones/List`, {
      method: "get",
      headers: {},
      queryParams: {
        deviceId,
        firmware,
        status,
        userQuery,
        carModelQuery,
        canQuery,
        vinQuery,
        algorithmQuery,
        versionQuery,
        includeData,
        pageNumber,
        pageSize,
      },
    });
  }

  function bckClonesGetData(cloneId) {
    return handleAuth(`frontend/Clones/GetData`, {
      method: "get",
      headers: {},
      queryParams: { cloneId },
    });
  }

  function bckClonesSetAttemptsCount(deviceId, value) {
    return handleAuth(`frontend/Clones/SetAttemptsCount`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ deviceId, value }),
    });
  }

  function bckCarAlarmFeaturesSet(id, carId, alarmId, type, value) {
    return handleAuth(`frontend/CarAlarmFeatures/Set`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id, carId, alarmId, type, value }),
    });
  }

  function bckCarAlarmFeaturesDuplicate(fromSubsystemId, toSubsystemId, type) {
    return handleAuth(`frontend/CarAlarmFeatures/Duplicate`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ fromSubsystemId, toSubsystemId, type }),
    });
  }

  function bckFilesRemoveUpdateChannelFile(fileId) {
    return handleAuth(`frontend/Files/RemoveUpdateChannelFile`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: `"${encodeURIComponent(fileId)}"`,
    });
  }

  function bckFilesRemoveCarModelMountingMap(id) {
    return handleAuth(`frontend/Files/RemoveCarModelMountingMap`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: `"${encodeURIComponent(id)}"`,
    });
  }

  function bckDevicesLatestFirmwareVersion(name) {
    return handleAuth(`frontend/Devices/LatestFirmwareVersion`, {
      method: "get",
      headers: {},
      queryParams: { name },
    });
  }

  function bckStatsTotalUsers() {
    return handleAuth(`frontend/Stats/TotalUsers`, {
      method: "get",
      headers: {},
    });
  }

  function bckStatsOnlineUsers() {
    return handleAuth(`frontend/Stats/OnlineUsers`, {
      method: "get",
      headers: {},
    });
  }

  function bckPushesSend(
    type,
    clientType,
    recipientType,
    recipient,
    header,
    body,
    clickLink,
    videoLink,
    lang
  ) {
    return handleAuth(`frontend/Pushes/Send`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        type,
        clientType,
        recipientType,
        recipient,
        header,
        body,
        clickLink,
        videoLink,
        lang,
      }),
    });
  }

  function bckMailingSend(isBulk, email, title, body) {
    return handleAuth(`frontend/Mailing/Send`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ isBulk, email, title, body }),
    });
  }

  function bckCarsCreateOrUpdateBrand(id, title) {
    return handleAuth(`frontend/Cars/CreateOrUpdateBrand`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id, title }),
    });
  }

  function bckCarsRemoveBrand(id) {
    return handleAuth(`frontend/Cars/RemoveBrand`, {
      method: "post",
      headers: {},
      queryParams: { id },
    });
  }

  function bckCarsCreateOrUpdateModel(id, brandId, title, years, type) {
    return handleAuth(`frontend/Cars/CreateOrUpdateModel`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id, brandId, title, years, type }),
    });
  }

  function bckCarsCreateOrUpdateEquipment(
    id,
    modelId,
    title,
    canCode,
    canLock,
    canLockAlarms,
    canLockTooltip,
    autostart,
    autostartAlarms,
    autostartTooltip,
    description1,
    description2,
    statuses,
    language,
    digitalBusesGroups
  ) {
    return handleAuth(`frontend/Cars/CreateOrUpdateEquipment`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id,
        modelId,
        title,
        canCode,
        language,
        canLock,
        canLockAlarms,
        canLockTooltip,
        autostart,
        autostartAlarms,
        autostartTooltip,
        description1,
        description2,
        statuses,
        digitalBusesGroups,
      }),
    });
  }

  function bckSettingsCreateOrUpdateStructure(
    id,
    name,
    parentId,
    startByte,
    startBit,
    mapper,
    lengthByte,
    lengthBit,
    divider,
    prototypeId,
    count,
    optionStructs,
    option2Value
  ) {
    return handleAuth(`frontend/Settings/CreateOrUpdateStructure`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id,
        name,
        parentId,
        startByte,
        startBit,
        mapper,
        lengthByte,
        lengthBit,
        divider,
        prototypeId,
        count,
        optionStructs,
        option2Value,
      }),
    });
  }

  function bckSettingsCreateOrUpdateLogics(logics) {
    return handleAuth(`frontend/Settings/CreateOrUpdateLogics`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(logics),
    });
  }

  function bckSettingsCreateOrUpdateChannel(channel) {
    return handleAuth(`frontend/Settings/CreateOrUpdateChannel`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(channel),
    });
  }

  function bckSettingsCreateOrUpdateChannelIo(io) {
    return handleAuth(`frontend/Settings/CreateOrUpdateChannelIo`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(io),
    });
  }

  function bckSettingsCreateOrUpdateAlarmMenu(alarmMenu) {
    return handleAuth(`frontend/Settings/CreateOrUpdateAlarmMenu`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(alarmMenu),
    });
  }

  function bckSettingsCreateOrUpdateWire(wire) {
    return handleAuth(`frontend/Settings/CreateOrUpdateWire`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(wire),
    });
  }

  function bckSettingsCreateOrUpdateWireColor(wireColor) {
    return handleAuth(`frontend/Settings/CreateOrUpdateWireColor`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(wireColor),
    });
  }

  function bckSettingsCreateOrUpdateColor(color) {
    return handleAuth(`frontend/Settings/CreateOrUpdateColor`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(color),
    });
  }

  function bckCloneTipsCreateOrUpdate(tip) {
    return handleAuth(`frontend/CloneTips/CreateOrUpdate`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tip),
    });
  }

  function bckSettingsCreateOrUpdateMenu(menuJson) {
    return handleAuth(`frontend/Settings/CreateOrUpdateMenu`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(menuJson),
    });
  }

  function bckTranslationsCreateOrUpdate(translation) {
    return handleAuth(`frontend/Translations/CreateOrUpdate`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(translation),
    });
  }

  function bckOrderRequestsCreateOrUpdateTopic(topic) {
    return handleAuth(`frontend/OrderRequests/CreateOrUpdateTopic`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(topic),
    });
  }

  function bckCarsMountingPointGuideTypeCreateOrUpdate(lang, type) {
    return handleAuth(`frontend/Cars/CreateOrUpdateMountingPointGuideType`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(type),
      queryParams: { lang },
    });
  }

  function bckCarsMountingPointGuideCreateOrUpdate(carId, lang, guide) {
    return handleAuth(`frontend/Cars/CreateOrUpdateMountingPointGuide`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(guide),
      queryParams: { carId, lang },
    });
  }

  function bckOpenManualRequestCreateOrUpdate(request) {
    return handleAuth(
      `frontend/OpenManualRequests/CreateOrUpdateOpenManualRequest`,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(request),
        queryParams: {},
      }
    );
  }

  function bckOpenManualCreateOrUpdate(manual) {
    return handleAuth(`frontend/OpenManuals/CreateOrUpdateOpenManual`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(manual),
      queryParams: {},
    });
  }

  function bckOpenManualRequestFileCreateOrUpdate(file) {
    return handleAuth(
      `frontend/OpenManualRequests/CreateOrUpdateOpenManualRequestFile`,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(file),
        queryParams: {},
      }
    );
  }

  function bckOpenManualFileCreateOrUpdate(file) {
    return handleAuth(`frontend/OpenManuals/CreateOrUpdateOpenManualFile`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(file),
      queryParams: {},
    });
  }

  function bckCarsMountingPointGuideImageCreateOrUpdate(guideId, image) {
    return handleAuth(`frontend/Cars/CreateOrUpdateMountingPointGuideImage`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(image),
      queryParams: { guideId },
    });
  }

  function bckCarsMountingSchemeCreateOrUpdate(carId, lang, scheme) {
    return handleAuth(`frontend/Cars/CreateOrUpdateMountingScheme`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(scheme),
      queryParams: { carId, lang },
    });
  }

  function bckCarsMountingMapCreateOrUpdate(carId, lang, map) {
    return handleAuth(`frontend/Cars/CreateOrUpdateCarMountingMap`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(map),
      queryParams: { carId, lang },
    });
  }

  function bckArticlesCreateOrUpdate(article) {
    return handleAuth(`frontend/Articles/CreateOrUpdate`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(article),
    });
  }

  function bckDevicesCreateOrUpdateAlias(alias) {
    return handleAuth(`frontend/Devices/CreateOrUpdateAlias`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(alias),
    });
  }

  function bckSettingsRestore(settingsFull) {
    return handleAuth(`frontend/Settings/Restore`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(settingsFull),
    });
  }

  function bckDevicesCreateOrUpdateDevice(
    id,
    name,
    type,
    subsystems,
    creationTime
  ) {
    return handleAuth(`frontend/Devices/CreateOrUpdateDevice`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id, name, type, subsystems, creationTime }),
    });
  }

  function bckDevicesCreateOrUpdateSubsystem(
    id,
    name,
    type,
    title,
    canEquipments,
    autostartEquipments,
    features,
    digitalBuses,
    creationTime
  ) {
    return handleAuth(`frontend/Devices/CreateOrUpdateSubsystem`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id,
        name,
        type,
        title,
        canEquipments,
        autostartEquipments,
        features,
        digitalBuses,
        creationTime,
      }),
    });
  }

  function bckDevicesCreateOrUpdateSubsystemFirmware(
    id,
    subsystemId,
    type,
    description,
    lang,
    isPublished,
    version
  ) {
    return handleAuth(`frontend/Devices/CreateOrUpdateSubsystemFirmware`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id,
        subsystemId,
        type,
        description,
        lang,
        isPublished,
        version,
      }),
    });
  }

  function bckCarsRemoveModel(id) {
    return handleAuth(`frontend/Cars/RemoveModel`, {
      method: "post",
      headers: {},
      queryParams: { id },
    });
  }

  function bckCarsRemoveEquipment(id) {
    return handleAuth(`frontend/Cars/RemoveEquipment`, {
      method: "post",
      headers: {},
      queryParams: { id },
    });
  }

  function bckDevicesRemoveDevice(id) {
    return handleAuth(`frontend/Devices/RemoveDevice`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: id,
    });
  }

  function bckDevicesRemoveSubsystem(id) {
    return handleAuth(`frontend/Devices/RemoveSubsystem`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: id,
    });
  }

  function bckSettingsRemoveStructure(id) {
    return handleAuth(`frontend/Settings/RemoveStructure`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: id,
    });
  }

  function bckSettingsRemoveMenu(id) {
    return handleAuth(`frontend/Settings/RemoveMenu`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: id,
    });
  }

  function bckSettingsRemoveLogics(id) {
    return handleAuth(`frontend/Settings/RemoveLogics`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: id,
    });
  }

  function bckSettingsRemoveChannel(id) {
    return handleAuth(`frontend/Settings/RemoveChannel`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: id,
    });
  }

  function bckSettingsRemoveAlarmMenu(id) {
    return handleAuth(`frontend/Settings/RemoveAlarmMenu`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: id,
    });
  }

  function bckSettingsRemoveWire(id) {
    return handleAuth(`frontend/Settings/RemoveWire`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: id,
    });
  }

  function bckSettingsRemoveWireColor(id) {
    return handleAuth(`frontend/Settings/RemoveWireColor`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: id,
    });
  }

  function bckSettingsRemoveColor(id) {
    return handleAuth(`frontend/Settings/RemoveColor`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: id,
    });
  }

  function bckCloneTipsRemove(id) {
    return handleAuth(`frontend/CloneTips/Remove`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: id,
    });
  }

  function bckDevicesRemoveSubsystemFirmware(id) {
    return handleAuth(`frontend/Devices/RemoveSubsystemFirmware`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: id,
    });
  }

  function bckAddressGetLocationString(addressId) {
    return handleAuth(`frontend/Address/GetLocationString`, {
      method: "get",
      headers: {},
      queryParams: { addressId },
    });
  }

  function bckAddressGetCountries() {
    return handleAuth(`frontend/Address/GetCountries`, {
      method: "get",
      headers: {},
    });
  }

  function bckCarsMountingPointGuideTypeGet(lang) {
    return handleAuth(`frontend/Cars/GetMountingPointGuideTypes`, {
      method: "get",
      headers: {},
      queryParams: { lang },
    });
  }

  function bckAddressGetStates(country) {
    return handleAuth(`frontend/Address/GetStates`, {
      method: "get",
      headers: {},
      queryParams: { country },
    });
  }

  function bckAddressGetLocalities(state) {
    return handleAuth(`frontend/Address/GetLocalities`, {
      method: "get",
      headers: {},
      queryParams: { state },
    });
  }

  function bckConfigurationsDuplicate(configId) {
    return handleAuth(`frontend/Configurations/Duplicate`, {
      method: "post",
      headers: {},
      queryParams: { configId },
    });
  }

  function bckSettingsListMenus() {
    return handleAuth(`frontend/Settings/ListMenus`, {
      method: "get",
      headers: {},
    });
  }

  function bckTechsupportRequestsList(
    userQuery,
    status,
    filterTag,
    pageNumber,
    pageSize
  ) {
    return handleAuth(`frontend/TechsupportRequests/List`, {
      method: "get",
      headers: {},
      queryParams: { userQuery, status, filterTag, pageNumber, pageSize },
    });
  }

  function bckOrderRequestsList(userQuery, status, pageNumber, pageSize) {
    return handleAuth(`frontend/OrderRequests/List`, {
      method: "get",
      headers: {},
      queryParams: { userQuery, status, pageNumber, pageSize },
    });
  }

  function bckOrderRequestsListTopics(lang) {
    return handleAuth(`frontend/OrderRequests/ListTopics`, {
      method: "get",
      headers: {},
      queryParams: { lang },
    });
  }

  function bckArticlesList(title, type, status, lang, pageNumber, pageSize) {
    return handleAuth(`frontend/Articles/List`, {
      method: "get",
      headers: {},
      queryParams: { title, type, status, lang, pageNumber, pageSize },
    });
  }

  function bckOpenManualRequestsList(
    id,
    title,
    login,
    status,
    pageNumber,
    pageSize
  ) {
    return handleAuth(`frontend/OpenManualRequests/List`, {
      method: "get",
      headers: {},
      queryParams: { id, title, login, status, pageNumber, pageSize },
    });
  }

  function bckOpenManualsList(id, equipmentId, title, pageNumber, pageSize) {
    return handleAuth(`frontend/OpenManuals/List`, {
      method: "get",
      headers: {},
      queryParams: { id, equipmentId, title, pageNumber, pageSize },
    });
  }

  function bckDevicesListAliases() {
    return handleAuth(`frontend/Devices/ListAliases`, {
      method: "get",
      headers: {},
    });
  }

  function bckArticlesTags() {
    return handleAuth(`frontend/Articles/Tags`, {
      method: "get",
      headers: {},
      queryParams: {},
    });
  }

  function bckArticlesFullInfo(articleId) {
    return handleAuth(`frontend/Articles/FullInfo`, {
      method: "get",
      headers: {},
      queryParams: { articleId },
    });
  }

  function bckTechsupportRequestsAssign(requestId) {
    return handleAuth(`frontend/TechsupportRequests/Assign`, {
      method: "post",
      headers: {},
      queryParams: { requestId },
    });
  }

  function bckOrderRequestsAssign(requestId) {
    return handleAuth(`frontend/OrderRequests/Assign`, {
      method: "post",
      headers: {},
      queryParams: { requestId },
    });
  }

  function bckOrderRequestsRemoveTopic(id) {
    return handleAuth(`frontend/OrderRequests/RemoveTopic`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: id,
    });
  }

  function bckArticlesRemove(id) {
    return handleAuth(`frontend/Articles/Remove`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: id,
    });
  }

  function bckCarsMountingPointGuideTypeRemove(id) {
    return handleAuth(`frontend/Cars/RemoveMountingPointGuideType`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: id,
    });
  }

  function bckCarsMountingPointGuideRemove(id) {
    return handleAuth(`frontend/Cars/RemoveMountingPointGuide`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: id,
    });
  }

  function bckCarsMountingSchemeRemove(id) {
    return handleAuth(`frontend/Cars/RemoveMountingScheme`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: id,
    });
  }

  function bckOpenManualRequestRemove(id) {
    return handleAuth(`frontend/OpenManualRequests/RemoveOpenManualRequest`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: id,
    });
  }

  function bckOpenManualRemove(id) {
    return handleAuth(`frontend/OpenManuals/RemoveOpenManual`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: id,
    });
  }

  function bckOpenManualCreateFromRequest(requestId) {
    return handleAuth(`frontend/OpenManuals/CreateOpenManualFromRequest`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: requestId,
    });
  }

  function bckOpenManualRequestFileRemove(id) {
    return handleAuth(
      `frontend/OpenManualRequests/RemoveOpenManualRequestFile`,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: id,
      }
    );
  }

  function bckOpenManualFileRemove(id) {
    return handleAuth(`frontend/OpenManuals/RemoveOpenManualFile`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: id,
    });
  }

  function bckCarsMountingMapRemove(id) {
    return handleAuth(`frontend/Cars/RemoveCarMountingMap`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: id,
    });
  }

  function bckCarsMountingPointGuideImageRemove(id) {
    return handleAuth(`frontend/Cars/RemoveMountingPointGuideImage`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: id,
    });
  }

  function bckDevicesRemoveAlias(id) {
    return handleAuth(`frontend/Devices/RemoveAlias`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: id,
    });
  }

  function bckTechsupportRequestsClose(requestId, comment, isFinal, tag) {
    return handleAuth(`frontend/TechsupportRequests/Close`, {
      method: "post",
      headers: {},
      queryParams: { requestId, comment, isFinal, tag },
    });
  }

  function bckOrderRequestsClose(requestId, comment, isFinal) {
    return handleAuth(`frontend/OrderRequests/Close`, {
      method: "post",
      headers: {},
      queryParams: { requestId, comment, isFinal },
    });
  }

  function bckSettingsListAlarmMenus() {
    return handleAuth(`frontend/Settings/ListAlarmMenus`, {
      method: "get",
      headers: {},
    });
  }

  function bckSettingsGetFullMenu(id, lang) {
    return handleAuth(`frontend/Settings/GetFullMenu`, {
      method: "get",
      headers: {},
      queryParams: { id, lang },
    });
  }

  function bckSettingsGetFullMenuByName(name, lang) {
    return handleAuth(`frontend/Settings/GetFullMenuByName`, {
      method: "get",
      headers: {},
      queryParams: { name, lang },
    });
  }

  function bckUsergroupGet(
    login,
    searchLogin,
    searchCompany,
    searchAddress,
    searchComment,
    asVisible,
    pageNumber,
    pageSize
  ) {
    return handleAuth(`frontend/Usergroup`, {
      method: "get",
      headers: {},
      queryParams: {
        login,
        searchLogin,
        searchCompany,
        searchAddress,
        searchComment,
        asVisible,
        pageNumber,
        pageSize,
      },
    });
  }

  function bckInfraGetChatSocket() {
    return handleAuth(`frontend/infra/GetChatSocket`, {
      method: "get",
      headers: {},
    });
  }

  function bckChatGetRoomMessages(
    userLogin,
    includeMountEvents,
    pageNumber,
    pageSize
  ) {
    return handleAuth(`frontend/Chat/GetRoomMessages`, {
      method: "get",
      headers: {},
      queryParams: { userLogin, includeMountEvents, pageNumber, pageSize },
    });
  }

  function bckChatGetRooms(searchLogin, searchAnyUnread, pageNumber, pageSize) {
    return handleAuth(`frontend/Chat/GetRooms`, {
      method: "get",
      headers: {},
      queryParams: { searchLogin, searchAnyUnread, pageNumber, pageSize },
    });
  }

  function bckLogsGetCloneInfo(cloneId, lang) {
    return handleAuth(`frontend/Logs/GetCloneInfo`, {
      method: "get",
      headers: {},
      queryParams: { cloneId, lang },
    });
  }

  function bckTechsupportRequestsAnyOpened() {
    return handleAuth(`frontend/TechsupportRequests/AnyOpened`, {
      method: "get",
      headers: {},
    });
  }

  function bckOpenManualRequestsGetSentCount() {
    return handleAuth(`frontend/OpenManualRequests/GetSentCount`, {
      method: "get",
      headers: {},
    });
  }

  function bckOrderRequestsAnyOpened() {
    return handleAuth(`frontend/OrderRequests/AnyOpened`, {
      method: "get",
      headers: {},
    });
  }

  function bckConfigurationsGetInvalidConfigsCount() {
    return handleAuth(`frontend/Configurations/GetInvalidConfigsCount`, {
      method: "get",
      headers: {},
    });
  }

  function bckUsergroupGetPossibleUsers(login) {
    return handleAuth(`frontend/Usergroup/GetPossibleUsers`, {
      method: "get",
      headers: {},
      queryParams: { login },
    });
  }

  function bckLogsGetUsergroupOwners(id) {
    return handleAuth(`frontend/Logs/GetUsergroupOwners`, {
      method: "get",
      headers: {},
      queryParams: { id },
    });
  }

  function bckFastMountsGetUsergroupOwners(id) {
    return handleAuth(`frontend/FastMounts/GetUsergroupOwners`, {
      method: "get",
      headers: {},
      queryParams: { id },
    });
  }

  function bckUsersGetUsergroupOwners(id) {
    return handleAuth(`frontend/Users/GetUsergroupOwners`, {
      method: "get",
      headers: {},
      queryParams: { id },
    });
  }

  function bckUsersGetInfo(id) {
    return handleAuth(`frontend/Users/GetInfo`, {
      method: "get",
      headers: {},
      queryParams: { id },
    });
  }

  function bckUsersIsApiUserOnline(userId) {
    return handleAuth(`frontend/Users/IsApiUserOnline`, {
      method: "get",
      headers: {},
      queryParams: { userId },
    });
  }

  function bckUsersAnyWithPendingUsergroupMembers() {
    return handleAuth(`frontend/Users/AnyWithPendingUsergroupMembers`, {
      method: "get",
      headers: {},
    });
  }

  function bckChatAnyUnreadMessages() {
    return handleAuth(`frontend/Chat/AnyUnreadMessages`, {
      method: "get",
      headers: {},
    });
  }

  function bckChatMarkFavouriteRoom(roomId, value) {
    return handleAuth(`frontend/Chat/MarkFavouriteRoom`, {
      method: "post",
      headers: {},
      queryParams: { roomId, value },
    });
  }

  function bckPackagesFind(deviceId) {
    return handleAuth(`frontend/Packages/Find`, {
      method: "get",
      headers: {},
      queryParams: { deviceId },
    });
  }

  function bckUsergroupDeleteMember(owner, member) {
    return handleAuth(`frontend/Usergroup/DeleteMember`, {
      method: "post",
      headers: {},
      queryParams: { owner, member },
    });
  }

  function bckUsergroupAddMember(owner, member) {
    return handleAuth(`frontend/Usergroup/AddMember`, {
      method: "post",
      headers: {},
      queryParams: { owner, member },
    });
  }

  function bckUsersSetUserGroupVisibility(login, value) {
    return handleAuth(`frontend/Users/SetUserGroupVisibility`, {
      method: "post",
      headers: {},
      queryParams: { login, value },
    });
  }

  function bckUsersEditAddress(userId, address) {
    return handleAuth(`frontend/Users/EditAddress`, {
      method: "post",
      headers: {},
      queryParams: { userId, address },
    });
  }

  function bckUsersEditCompany(userId, company) {
    return handleAuth(`frontend/Users/EditCompany`, {
      method: "post",
      headers: {},
      queryParams: { userId, company },
    });
  }

  function bckUsersEditComment(userId, comment) {
    return handleAuth(`frontend/Users/EditComment`, {
      method: "post",
      headers: {},
      queryParams: { userId, comment },
    });
  }

  function bckUsersEditPhone(userId, phone) {
    return handleAuth(`frontend/Users/EditPhone`, {
      method: "post",
      headers: {},
      queryParams: { userId, phone },
    });
  }

  function bckUsersEditFullname(userId, fullname) {
    return handleAuth(`frontend/Users/EditFullname`, {
      method: "post",
      headers: {},
      queryParams: { userId, fullname },
    });
  }

  function bckLogsGetPossibleLogTypes(lang) {
    return handleAuth(`frontend/Logs/GetPossibleLogTypes`, {
      method: "get",
      headers: {},
      queryParams: { lang },
    });
  }

  function bckUsersGetFullInfo(login) {
    return handleAuth(`frontend/Users/GetFullInfo`, {
      method: "get",
      headers: {},
      queryParams: { login },
    });
  }

  function bckStatsAuthCounts(from, till) {
    return handleAuth(`frontend/Stats/AuthCounts`, {
      method: "get",
      headers: {},
      queryParams: { from: from.toJSON(), till: till.toJSON() },
    });
  }

  function bckStatsAuthCountsDynamics(from, till) {
    return handleAuth(`frontend/Stats/AuthCountsDynamics`, {
      method: "get",
      headers: {},
      queryParams: { from: from.toJSON(), till: till.toJSON() },
    });
  }

  function bckStatsAuthCountsUniqueUsersDynamics(from, till) {
    return handleAuth(`frontend/Stats/AuthCountsUniqueUsersDynamics`, {
      method: "get",
      headers: {},
      queryParams: { from: from.toJSON(), till: till.toJSON() },
    });
  }

  function bckStatsTopAlarmsDynamics(from, till, alarmName, location) {
    return handleAuth(`frontend/Stats/TopAlarmsDynamics`, {
      method: "get",
      headers: {},
      queryParams: {
        from: from.toJSON(),
        till: till.toJSON(),
        alarmName,
        location,
      },
    });
  }

  function bckStatsTopAlarms(from, till, alarmName, location) {
    return handleAuth(`frontend/Stats/TopAlarms`, {
      method: "get",
      headers: {},
      queryParams: {
        from: from.toJSON(),
        till: till.toJSON(),
        alarmName,
        location,
      },
    });
  }

  function bckStatsTopCars(from, till, brandId, modelId, location, lang) {
    return handleAuth(`frontend/Stats/TopCars`, {
      method: "get",
      headers: {},
      queryParams: {
        from: from.toJSON(),
        till: till.toJSON(),
        lang,
        brandId,
        modelId,
        location,
      },
    });
  }

  function bckUsersGetPushRules() {
    return handleAuth(`frontend/Users/GetPushRules`, {
      method: "get",
      headers: {},
    });
  }

  function bckUsersSetPushRules(types) {
    return handleAuth(`frontend/Users/SetPushRules`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ types }),
    });
  }

  function bckUsersSetPreferences(brandIds, modelIds) {
    return handleAuth(`frontend/Users/SetPreferences`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ brandIds, modelIds }),
    });
  }

  function bckStatsTopCarsDynamics(
    from,
    till,
    brandId,
    modelId,
    location,
    lang
  ) {
    return handleAuth(`frontend/Stats/TopCarsDynamics`, {
      method: "get",
      headers: {},
      queryParams: {
        from: from.toJSON(),
        till: till.toJSON(),
        lang,
        brandId,
        modelId,
        location,
      },
    });
  }

  function bckStatsUsersDevices(from, till, login, location) {
    return handleAuth(`frontend/Stats/UsersDevices`, {
      method: "get",
      headers: {},
      queryParams: {
        from: from.toJSON(),
        till: till.toJSON(),
        location,
        login,
      },
    });
  }

  function bckStatsTechsupportRequests(
    from,
    till,
    text,
    deviceName,
    location,
    tag
  ) {
    return handleAuth(`frontend/Stats/TechsupportRequests`, {
      method: "get",
      headers: {},
      queryParams: {
        from: from.toJSON(),
        till: till.toJSON(),
        text,
        deviceName,
        location,
        tag,
      },
    });
  }

  function bckUsergroupSetMemberStatus(owner, member, status) {
    return handleAuth(`/frontend/Usergroup/SetMemberStatus`, {
      method: "post",
      headers: {},
      queryParams: { owner, member, status },
    });
  }

  return (
    <BackendContext.Provider
      value={{
        bckAddressGetCountries,
        bckAddressGetLocalities,
        bckAddressGetLocationString,
        bckAddressGetStates,
        bckAutohelperLogs,
        bckCarAlarmFeaturesDuplicate,
        bckCarAlarmFeaturesList,
        bckCarAlarmFeaturesSet,
        bckCarsCreateOrUpdateBrand,
        bckCarsCreateOrUpdateEquipment,
        bckCarsCreateOrUpdateModel,
        bckCarsGetEquipmentInfo,
        bckCarsListBrands,
        bckCarsListEquipments,
        bckCarsListModels,
        bckCarsRemoveBrand,
        bckCarsRemoveEquipment,
        bckCarsRemoveModel,
        bckChatAnyUnreadMessages,
        bckChatGetRoomMessages,
        bckChatGetRooms,
        bckCloneTipsCreateOrUpdate,
        bckCloneTipsList,
        bckCloneTipsRemove,
        bckClonesGetData,
        bckClonesList,
        bckClonesSetAttemptsCount,
        bckConfigurationCreateOrUpdate,
        bckConfigurationsDelete,
        bckConfigurationsDuplicate,
        bckConfigurationsGetAdditionalInfo,
        bckConfigurationsGetChecklist,
        bckConfigurationsGetDxsData,
        bckConfigurationsGetInvalidConfigsCount,
        bckConfigurationsList,
        bckDevicesCreateOrUpdateDevice,
        bckDevicesCreateOrUpdateSubsystem,
        bckDevicesCreateOrUpdateSubsystemFirmware,
        bckDevicesGetSubsystemInfo,
        bckDevicesLatestFirmwareVersion,
        bckDevicesList,
        bckDevicesListDevices,
        bckDevicesListSubsystems,
        bckDevicesRemoveDevice,
        bckDevicesRemoveSubsystem,
        bckDevicesRemoveSubsystemFirmware,
        bckFastMountsGetUsergroupOwners,
        bckFastMountsList,
        bckFastMountsSetComment,
        bckFastMountsSetState,
        bckFeedbackList,
        bckFilesRemoveUpdateChannelFile,
        bckFilesUploadAvatar,
        bckFilesUploadCarBrandImage,
        bckFilesUploadCarModelImage,
        bckFilesUploadChatFile,
        bckFilesUploadConfigurationImage,
        bckFilesUploadDeviceImage,
        bckFilesUploadMountingMap,
        bckFilesUploadSubsystemFirmware,
        bckFilesUploadSubsystemImage,
        bckFilesUploadUpdateChannelFile,
        bckInfraGetChatSocket,
        bckLogsGetCloneInfo,
        bckLogsGetPossibleLogTypes,
        bckLogsGetUsergroupOwners,
        bckLogsList,
        bckMailingList,
        bckMailingSend,
        bckPackagesFind,
        bckPushesList,
        bckPushesSend,
        bckQuestionsCreateOrUpdatePart,
        bckQuestionsCreateOrUpdateQuestion,
        bckQuestionsCreateOrUpdateSubpart,
        bckQuestionsDeletePart,
        bckQuestionsDeleteQuestion,
        bckQuestionsDeleteSubpart,
        bckQuestionsListParts,
        bckQuestionsListQuestions,
        bckQuestionsListSubparts,
        bckSettingsCreateOrUpdateAlarmMenu,
        bckSettingsCreateOrUpdateChannel,
        bckSettingsCreateOrUpdateChannelIo,
        bckSettingsCreateOrUpdateColor,
        bckSettingsCreateOrUpdateLogics,
        bckSettingsCreateOrUpdateMenu,
        bckSettingsCreateOrUpdateStructure,
        bckSettingsCreateOrUpdateWire,
        bckSettingsCreateOrUpdateWireColor,
        bckSettingsGenerateSettingsEditorInfo,
        bckSettingsGetFullMenu,
        bckSettingsGetFullMenuByName,
        bckSettingsListAlarmMenus,
        bckSettingsListMenus,
        bckSettingsRemoveAlarmMenu,
        bckSettingsRemoveChannel,
        bckSettingsRemoveColor,
        bckSettingsRemoveLogics,
        bckSettingsRemoveMenu,
        bckSettingsRemoveStructure,
        bckSettingsRemoveWire,
        bckSettingsRemoveWireColor,
        bckSettingsRestore,
        bckStatsAuthCounts,
        bckStatsAuthCountsDynamics,
        bckStatsAuthCountsUniqueUsersDynamics,
        bckStatsOnlineUsers,
        bckStatsTechsupportRequests,
        bckStatsTopAlarms,
        bckStatsTopAlarmsDynamics,
        bckStatsTopCars,
        bckStatsTopCarsDynamics,
        bckStatsTotalUsers,
        bckStatsUsersDevices,
        bckTechsupportRequestsAnyOpened,
        bckTechsupportRequestsAssign,
        bckTechsupportRequestsClose,
        bckTechsupportRequestsGetConfig,
        bckTechsupportRequestsGetFullInfo,
        bckTechsupportRequestsList,
        bckTranslationsCreateOrUpdate,
        bckTranslationsList,
        bckUpdateChannelAddUser,
        bckUpdateChannelCreateFile,
        bckUpdateChannelDuplicateFile,
        bckUpdateChannelEditFileDescription,
        bckUpdateChannelEditFileVersion,
        bckUpdateChannelEditRequirements,
        bckUpdateChannelGetActualVersion,
        bckUpdateChannelList,
        bckUpdateChannelListFiles,
        bckUpdateChannelListUsers,
        bckUpdateChannelRemoveUser,
        bckUpdateChannelSetIsPublished,
        bckUsergroupAddMember,
        bckUsergroupDeleteMember,
        bckUsergroupGet,
        bckUsergroupGetPossibleUsers,
        bckUsergroupSetMemberStatus,
        bckUsersActivate,
        bckUsersAnyWithPendingUsergroupMembers,
        bckUsersChangePassword,
        bckUsersCheckToken,
        bckUsersDeleteUser,
        bckUsersEditAddress,
        bckUsersEditComment,
        bckUsersEditCompany,
        bckUsersEditFullname,
        bckUsersEditPhone,
        bckUsersGetFullInfo,
        bckUsersGetInfo,
        bckUsersGetPushRules,
        bckUsersGetUsergroupOwners,
        bckUsersIsApiUserOnline,
        bckUsersListAll,
        bckUsersLogin,
        bckUsersLogout,
        bckUsersRequestPasswordReset,
        bckUsersResetPassword,
        bckUsersSetPreferences,
        bckUsersSetPushRules,
        bckUsersSetRole,
        bckUsersSetUserGroupVisibility,
        bckUsersSignIn,
        bckOrderRequestsAnyOpened,
        bckOrderRequestsAssign,
        bckOrderRequestsClose,
        bckOrderRequestsGetFullInfo,
        bckOrderRequestsList,
        bckOrderRequestsListTopics,
        bckOrderRequestsCreateOrUpdateTopic,
        bckOrderRequestsRemoveTopic,
        bckArticlesList,
        bckArticlesFullInfo,
        bckArticlesCreateOrUpdate,
        bckArticlesRemove,
        bckArticlesTags,
        bckFilesUploadArticleImage,
        bckFilesUploadArticleElementImage,
        bckDevicesListAliases,
        bckDevicesCreateOrUpdateAlias,
        bckDevicesRemoveAlias,
        bckChatMarkFavouriteRoom,
        bckFilesUploadCarModelMountingMap,
        bckFilesRemoveCarModelMountingMap,
        bckCarsMountingPointGuideTypeGet,
        bckCarsMountingPointGuideTypeRemove,
        bckCarsMountingPointGuideTypeCreateOrUpdate,
        bckCarsMountingPointGuideRemove,
        bckCarsMountingPointGuideCreateOrUpdate,
        bckCarsMountingPointGuideImageRemove,
        bckCarsMountingPointGuideImageCreateOrUpdate,
        bckFilesUploadMountingPointGuideImage,
        bckCarsMountingSchemeRemove,
        bckCarsMountingSchemeCreateOrUpdate,
        bckFilesUploadMountingScheme,
        bckCarsMountingMapRemove,
        bckCarsMountingMapCreateOrUpdate,
        bckFilesUploadCarMountingMap,
        bckFilesUploadOpenManualRequestFile,
        bckFilesUploadOpenManualFile,
        bckOpenManualRequestCreateOrUpdate,
        bckOpenManualRequestRemove,
        bckOpenManualRequestFileCreateOrUpdate,
        bckOpenManualRequestFileRemove,
        bckOpenManualRequestsList,
        bckOpenManualCreateOrUpdate,
        bckOpenManualRemove,
        bckOpenManualFileCreateOrUpdate,
        bckOpenManualFileRemove,
        bckOpenManualsList,
        bckOpenManualCreateFromRequest,
        bckOpenManualRequestsGetSentCount,
      }}
    >
      {children}
    </BackendContext.Provider>
  );
};

const useBackend = () => useContext(BackendContext);

export { BackendContext, BackendProvider, useBackend };
