import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Paper,
  TextField,
  Divider,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { useTranslation } from "react-i18next";

import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Edit as EditIcon,
  AddCircle as AddFileIcon,
  RemoveCircle as RemoveFileIcon,
  Upload as UploadFileIcon,
  PictureAsPdf as PdfIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

import {
  DialogActionTypes,
  useDialogContext,
} from "../../context/DialogContext";
import { useBackend } from "../../context/BackendContext";

import ImageUpload from "../../images/ImageUpload.jpg";

import ReactFileReader from "react-file-reader";
import { OpenManualStatuses } from "../../enums/OpenManualStatuses";

export default function OpenManualFull({ headerSetter, viewOnlyMode }) {
  const { t } = useTranslation();
  const dialogDispatch = useDialogContext();
  const backend = useBackend();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [manualId, setManualId] = useState(0);
  const [manual, setManual] = useState({});

  useEffect(() => {
    let params = queryString.parse(location.search);
    if (params.manualId) {
      setManualId(params.manualId);
    }
  }, [location]);

  useEffect(() => {
    if (manualId) {
      setIsLoading(true);
      headerSetter(t("Open manual") + ` #${manualId}`);
      backend.bckOpenManualsList(manualId, null, null, 1, 1).then((json) => {
        setIsLoading(false);
        if (json.code === 0) {
          let manual = json.content.list[0];
          console.log(manual);
          setManual(manual);
        }
      });
    }
  }, [backend, manualId, headerSetter, t]);

  const updateManual = useCallback(
    (newManual) => {
      return backend
        .bckOpenManualCreateOrUpdate({ ...newManual, id: manualId })
        .then((json) => {
          if (json.code === 0) {
            setManual((prev) => ({ ...prev, ...newManual }));
          }
        });
    },
    [backend, manualId]
  );

  const handleEditTitle = useCallback(() => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (title) => {
        updateManual({ title: title });
      },
      initialValue: manual.title,
      textLabel: t("Title"),
      title: t("Title"),
    });
  }, [dialogDispatch, manual.title, t, updateManual]);

  const handleEditDescription = useCallback(() => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (description) => {
        updateManual({ description: description });
      },
      initialValue: manual.description,
      textLabel: t("Description"),
      title: t("Description"),
      lines: 4,
    });
  }, [dialogDispatch, manual.description, t, updateManual]);

  const updateFile = useCallback(
    (newFile) => {
      backend.bckOpenManualFileCreateOrUpdate(newFile).then((json) => {
        if (json.code === 0) {
          setManual((prev) => {
            let newFiles = [...prev.files];
            let foundIndex = newFiles.findIndex((x) => x.id === newFile.id);
            newFiles[foundIndex] = newFile;
            return { ...prev, files: newFiles };
          });
        }
      });
    },
    [backend]
  );

  const handleEditFileDescription = useCallback(
    (file) => {
      dialogDispatch({
        type: DialogActionTypes.SimpleTextDialogOpen,
        handleConfirm: (description) => {
          let newFile = { ...file, description };
          updateFile(newFile);
        },
        initialValue: file.description,
        textLabel: t("Description"),
        title: t("Description"),
        lines: 4,
      });
    },
    [t, dialogDispatch, updateFile]
  );

  const handleAddFile = useCallback(() => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Add new file?"),
      handleConfirm: () => {
        let newOrder = Math.max(...manual.files.map((f) => f.order)) + 1;
        backend
          .bckOpenManualFileCreateOrUpdate({
            manualId: manualId,
            order: newOrder,
          })
          .then((json) => {
            if (json.code === 0) {
              setManual((prev) => {
                let newFiles = [
                  ...prev.files,
                  {
                    id: json.content,
                    manualId: manualId,
                    order: newOrder,
                    description: "",
                  },
                ];
                return { ...prev, files: newFiles };
              });
            }
          });
      },
    });
  }, [manualId, backend, dialogDispatch, manual.files, t]);

  const handleDeleteFile = useCallback(
    (file) => {
      dialogDispatch({
        type: DialogActionTypes.ConfirmationDialogOpen,
        userMessage: t("Delete file?"),
        handleConfirm: () => {
          backend.bckOpenManualFileRemove(file.id).then((json) => {
            if (json.code === 0) {
              setManual((prev) => {
                let newFiles = [...prev.files];
                let foundIndex = newFiles.findIndex((x) => x.id === file.id);
                newFiles.splice(foundIndex, 1);
                return { ...prev, files: newFiles };
              });
            }
          });
        },
      });
    },
    [backend, dialogDispatch, t]
  );

  const handleDeleteManual = useCallback(() => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Delete open manual?"),
      handleConfirm: () => {
        updateManual({ status: 3 }).then(() => {
          navigate(-1);
        });
      },
    });
  }, [dialogDispatch, navigate, t, updateManual]);

  const handleUploadFileAccepted = useCallback(
    (fileRecord, file) => {
      if (file.size > 32 * 1024 * 1024) {
        alert(t("File is too large"));
        return;
      }

      backend.bckFilesUploadOpenManualFile(fileRecord.id, file).then((json) => {
        if (json.code === 0) {
          setManual((prev) => {
            let newFiles = [...prev.files];
            let foundIndex = newFiles.findIndex((x) => x.id === fileRecord.id);
            let newFile = { ...newFiles[foundIndex], url: json.content.url };
            newFiles[foundIndex] = newFile;
            return { ...prev, files: newFiles };
          });
        }
      });
    },
    [backend, t]
  );

  const handleUploadFile = useCallback(
    (fileRecord, file) => {
      if (fileRecord.url) {
        dialogDispatch({
          type: DialogActionTypes.ConfirmationDialogOpen,
          userMessage: t("Overwrite file?"),
          handleConfirm: () => {
            handleUploadFileAccepted(fileRecord, file);
          },
        });
      } else {
        handleUploadFileAccepted(fileRecord, file);
      }
    },
    [dialogDispatch, handleUploadFileAccepted, t]
  );

  const handleSetStatus = useCallback(
    (e) => {
      updateManual({ status: e.target.value });
    },
    [updateManual]
  );

  return isLoading || manual.id === undefined || manual.id === null ? (
    <Grid
      container
      spacing={10}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "80vh" }}
    >
      <CircularProgress size={170} />
    </Grid>
  ) : (
    <>
      <Paper style={{ overflow: "auto", padding: "12px" }}>
        <div
          style={{
            marginTop: "12px",
            display: "flex",
            flexFlow: "row wrap",
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginRight: "24px", flexGrow: 1, minWidth: "52ch" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography variant="h2">{manual.title ?? ""}</Typography>
              <div style={{ flexGrow: 1 }} />
              {viewOnlyMode ? (
                <></>
              ) : (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginLeft: "16px",
                    }}
                  >
                    <FormControl
                      size="small"
                      margin="dense"
                      style={{ marginBottom: "8px" }}
                    >
                      <InputLabel>{t("Status")}</InputLabel>
                      <Select
                        value={manual.status}
                        label={t("Status")}
                        onChange={handleSetStatus}
                      >
                        <MenuItem key={1} value={1}>
                          {t(OpenManualStatuses[1])}
                        </MenuItem>
                        <MenuItem key={2} value={2}>
                          {t(OpenManualStatuses[2])}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      color="primary"
                      style={{ marginLeft: "16px" }}
                      onClick={handleEditTitle}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      style={{ color: "red" }}
                      onClick={handleDeleteManual}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
              )}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography>{manual.description ?? ""}</Typography>
              <div style={{ flexGrow: 1 }} />
              {viewOnlyMode ? (
                <></>
              ) : (
                <IconButton color="primary" onClick={handleEditDescription}>
                  <EditIcon />
                </IconButton>
              )}
            </div>
          </div>
        </div>
        <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />
        <Typography variant="h3">{t("Vehicle")}</Typography>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "64ch",
              flex: 1,
              marginRight: "32px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {manual.modelImageUrl ? (
              <div style={{ height: "240px" }}>
                <img
                  style={{ height: "100%" }}
                  src={manual.modelImageUrl}
                  alt=""
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              flex: 2,
            }}
          >
            <Typography variant="h4">{`${manual.brandTitle} | ${manual.modelTitle}`}</Typography>
            <Typography variant="h4">{manual.equipmentTitle}</Typography>
          </div>
        </div>
        <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Typography variant="h3">{t("Files")}</Typography>
          {manual.files.map((f) => (
            <div
              key={f.id}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div>
                {viewOnlyMode ? (
                  <></>
                ) : (
                  <IconButton
                    color="secondary"
                    onClick={() => handleDeleteFile(f)}
                  >
                    <RemoveFileIcon />
                  </IconButton>
                )}
              </div>
              <div
                style={{ width: "24ch", maxHeight: "100%" }}
                onClick={() => {
                  if (f.url) window.open(f.url, `_blank`);
                }}
              >
                {f.url ? (
                  f.url.endsWith(".pdf") ? (
                    <PdfIcon
                      style={{ fontSize: 80, width: "100%", maxHeight: "100%" }}
                    />
                  ) : (
                    <img
                      src={f.url}
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                      alt=""
                    />
                  )
                ) : (
                  <img
                    src={ImageUpload}
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                    alt=""
                  />
                )}
              </div>
              <div>
                {viewOnlyMode ? (
                  <></>
                ) : (
                  <ReactFileReader
                    handleFiles={(files) => handleUploadFile(f, files[0])}
                    fileTypes={["image/png", "image/jpeg", "application/pdf"]}
                  >
                    <IconButton color="primary">
                      <UploadFileIcon />
                    </IconButton>
                  </ReactFileReader>
                )}
              </div>
              <TextField
                label={t("Description")}
                margin="dense"
                value={f.description ?? ""}
                disabled
                multiline
                rows="3"
                fullWidth
              />
              {viewOnlyMode ? (
                <></>
              ) : (
                <IconButton
                  color="primary"
                  onClick={() => handleEditFileDescription(f)}
                >
                  <EditIcon />
                </IconButton>
              )}
            </div>
          ))}
          <div>
            {viewOnlyMode ? (
              <></>
            ) : (
              <IconButton color="primary" onClick={handleAddFile}>
                <AddFileIcon />
              </IconButton>
            )}
          </div>
        </div>
      </Paper>
    </>
  );
}
