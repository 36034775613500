import React, { useState, useEffect } from "react";
import { Grid, Typography, Paper, Button, TextField } from "@mui/material";

import AdvancedTablePagination from "../../components/AdvancedTablePagination";

import { useTranslation } from "react-i18next";
import { useBackend } from "../../context/BackendContext";
import { useNavigate } from "react-router";
import OpenManualsTable from "../../components/Tables/OpenManualsTable";

const initialRequestParams = {
  id: null,
  equipmentId: null,
  title: null,
  pageNumber: 0,
  pageSize: 10,
};

export default function OpenManuals({ equipmentId, shortMode }) {
  const backend = useBackend();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [requestParams, setRequestParams] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [searchTitle, setSearchTitle] = useState("");

  useEffect(() => {
    if (!requestParams) return;

    const refreshTable = (id, equipmentId, title, pageNumber, pageSize) => {
      backend
        .bckOpenManualsList(id, equipmentId, title, pageNumber + 1, pageSize)
        .then((json) => {
          if (json.code === 0) {
            setTableData((prev) => {
              return json.content.list;
            });
            setTotalRows((prev) => {
              return json.content.count;
            });
          }
        });
    };

    refreshTable(
      requestParams.id,
      requestParams.equipmentId,
      requestParams.title,
      requestParams.pageNumber,
      requestParams.pageSize
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestParams]);

  useEffect(() => {
    if (equipmentId) {
      setRequestParams({
        ...initialRequestParams,
        equipmentId: equipmentId,
      });
    } else {
      setRequestParams({ ...initialRequestParams });
    }
  }, [equipmentId]);

  const handleSearchPress = () => {
    setRequestParams({
      ...requestParams,
      title: searchTitle,
    });
  };

  const handleSearchResetPress = () => {
    setRequestParams({
      ...initialRequestParams,
      pageNumber: requestParams.pageNumber,
      pageSize: requestParams.pageSize,
    });
    setSearchTitle("");
  };

  const handleChangePage = (event, newPage) => {
    setRequestParams({
      ...requestParams,
      pageNumber: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    var newPageSize = parseInt(event.target.value, 10);
    setRequestParams({
      ...requestParams,
      pageNumber: 0,
      pageSize: newPageSize,
    });
  };

  const gotoOpenManualFull = (manual) => {
    navigate(`/app/openManuals/full?manualId=${manual.id}`);
  };

  const gotoOpenManualFullViewOnly = (manual) => {
    window.open(`/#/main/openManual?manualId=${manual.id}`);
  };

  return (
    <>
      <Paper style={{ overflow: "auto", padding: "24px", maxHeight: "85vh" }}>
        {equipmentId ? (
          <></>
        ) : (
          <Typography variant="h5" color="textSecondary">
            {t("Open manuals")}
          </Typography>
        )}
        {equipmentId ? (
          <></>
        ) : (
          <Grid
            container
            direction="column"
            style={{ paddingTop: "12px" }}
            spacing={2}
          >
            <Grid container item alignContent="center">
              <Grid item>
                <TextField
                  label={t("Title")}
                  value={searchTitle}
                  onChange={(e) => setSearchTitle(e.target.value)}
                  style={{ marginRight: "12px" }}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item>
                <Button
                  onClick={handleSearchPress}
                  variant="contained"
                  color="primary"
                >
                  {t("Search")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={handleSearchResetPress}
                  variant="contained"
                  color="primary"
                >
                  {t("Reset parameters")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}

        {equipmentId ? (
          <OpenManualsTable
            data={tableData}
            handleEditManual={
              shortMode ? gotoOpenManualFullViewOnly : gotoOpenManualFull
            }
            shortMode={shortMode}
          />
        ) : (
          <AdvancedTablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            totalRows={totalRows}
            pageSize={requestParams?.pageSize ?? 10}
            pageNumber={requestParams?.pageNumber ?? 0}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          >
            <OpenManualsTable
              data={tableData}
              handleEditManual={gotoOpenManualFull}
            />
          </AdvancedTablePagination>
        )}
      </Paper>
    </>
  );
}
